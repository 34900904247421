import { Suspense, memo, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { RestAPI } from "@/utils/api-rest";

import { useMatchMedia } from "primereact/hooks";
import { FarmTreeView } from "./FarmTreeView";

import { applyFormula } from "../../utils/area";
import { capitalize } from "../../components/Form/Form.js";
import getBaseLangs from "../../utils/language";
import ErrorBoundary from "../Utils/ErrorBoundary";
import LoadingDialog from "../Utils/LoadingDialog";
import Selector from "./AccountSelector";
import { FarmContext } from "@/contexts/FarmContext";

const defaultPrefijo = import.meta.env.VITE_DEFAULT_PREFIJO_CAMPO;
//const addPrefijo = (t, { name, area, prefijo: label }, t) => `${t(label, "{0}").wiseFormat(name)}${area}`;
const addPrefijo = ({ name, area, showEstimate, kilo_unidad }, t) =>
    `${name}${area ? ` (${showEstimate}${t("general.number", { value: area })} ${kilo_unidad})` : ""}`;

const transformData = async (data) => {
    const shouldExpand = data.length < 10;
    let cuenta_root_id = null;

    const { cuentas } = data.reduce((res, cuenta) => {
    // we could have and account twice, if you have read permission for some farms, and write for others
        const prev = res.exists[cuenta.cuenta_id];
        const existsPrev = prev && prev.farms ? true : false;
        const subChild = existsPrev ? res.cuentas[prev.index].subChild : [];
        const farms = existsPrev ? prev.farms : {};
        cuenta_root_id = `cuenta-${cuenta.cuenta_id}`;

        let areaToShow;
        let showEstimate;
        cuenta.campos.forEach((campo) => {
            if (!farms[campo.id]) {
                const sup_calc = campo.sup_calcs && campo.sup_calcs.total;
                if (campo.superficie) {
                    areaToShow = campo.superficie;
                    showEstimate = "";
                } else if (sup_calc) {
                    areaToShow = applyFormula(sup_calc, campo.formula_from_kilo_base);
                    showEstimate = "~";
                } else {
                    areaToShow = undefined;
                    showEstimate = "";
                }

                subChild.push({
                    id: `campo-${campo.id}`,
                    name: campo.nombre,
                    area: areaToShow,
                    idRoot: cuenta_root_id,
                    latitud: campo.latitud,
                    longitud: campo.longitud,
                    prefijo: campo.prefijo || defaultPrefijo,
                    crops: campo.cultivos && campo.cultivos.join("-"),
                    especie: campo.especie,
                    sup_calc,
                    formula_from_kilo_base: campo.formula_from_kilo_base,
                    showEstimate,
                    kilo_unidad: campo.kilo_unidad || "",
                    estado_billing:campo.estado_billing
                });
                farms[campo.id] = true;
            }
        });

        if (!existsPrev) {
            res.exists[cuenta.cuenta_id] = { index: res.cuentas.length, farms };
            res.cuentas.push({
                id: cuenta_root_id,
                name: cuenta.cuenta,
                subChild: subChild,
                hasChild: false,
                expanded: shouldExpand
            });
        }

        return res;
    }, { cuentas: [], exists: {} });

    return cuentas;
};

const updateFarmData = (newData, dataSource, setDataSource, valueSelected) => {
    const cuentaKey = (newData && newData.cuenta_id) ? `cuenta-${newData.cuenta_id}` : null;
    const campoKey = (newData && newData.campo_id) ? `campo-${newData.campo_id}` : null;
    if (!Array.isArray(dataSource) || !cuentaKey || !campoKey) return;

    const cuenta = dataSource.find((cuenta) => cuenta.id === cuentaKey);
    if (!cuenta || !Array.isArray(cuenta.subChild)) return;

    const campoIdx = cuenta.subChild.findIndex((oldData) => oldData.id === campoKey);
    const campo = cuenta.subChild[campoIdx];
    if (!campo) return;
    let modified = false;

    if (newData.nombre !== undefined && campo.name !== newData.nombre) {
        campo.name = newData.nombre;
        modified = true;
    }

    const newPrefijo = newData.prefijo !== undefined ? (newData.prefijo || defaultPrefijo) : undefined;
    if (newPrefijo !== undefined && campo.prefijo !== newPrefijo) {
        campo.prefijo = newPrefijo;
        modified = true;
    }

    let areaToShow = null;
    let showEstimate = "";
    if (newData.superficie !== undefined) {
        if (newData.superficie) {
            areaToShow = newData.superficie;
        } else if (campo.sup_calc) {
            areaToShow = applyFormula(campo.sup_calc, campo.formula_from_kilo_base);
            showEstimate = "~";
        }
    }
    if (campo.area !== areaToShow || campo.showEstimate !== showEstimate) {
        campo.area = areaToShow;
        campo.showEstimate = showEstimate;
        modified = true;
    }

    if (modified) {
        const newObj = { ...campo };
        cuenta.subChild[campoIdx] = newObj;
        const selectedId = valueSelected.current && valueSelected.current.id;
        if (selectedId === newObj.id) valueSelected.current = newObj;
        setDataSource((ds) => ({ ...ds })); // force re-render
    }
};

const getParams = (location) => {
    const params = new URLSearchParams(location && location.search);
    const account = parseInt(params.get("account"), 10);
    const farm = parseInt(params.get("farm"), 10) || parseInt(params.get("farmblack"), 10);
    const ret = {};

    if (farm) {
        ret.farm = farm;
    } else if (account) {
        ret.account = account;
    }
    return ret;
};

const redirectFarm = (history, navigate, farm) => {
    if (!farm || !farm.id) return;
    const path = "/farms";
    const idFarm = farm.id.split("-")[1];
    const search = `?farm=${idFarm}`;
    const location = history.location; // we use history.location to get the exact url at this moment (and not the one at render init)

    const redirect = (location.pathname !== path) || (location.search !== search);
    if (redirect) {
        navigate({ pathname: path, search: search }, { state: { expanded: [], updateTree: true } });
    }
};

// eslint-disable-next-line react/display-name
const FarmElement = memo(({ isSelected, setShow, history, navigate, farm, t, lng }) => (
    <div
        className={`${isSelected ? "lista-campo-seleccionado" : "lista-campos"} ${farm.estado_billing==="READY"?"farmReady":""}`}
        onClick={() => {
            redirectFarm(history, navigate, farm);
            setShow(true, true);
        }}
    >
        <span className="menu-icon-campo" />
        {farm.estado_billing==="READY"?<span className='menu-icons-right icono-billing' />:null}
        <p className='campo-nombre'>{farm.name ? addPrefijo(farm, t) : "-"}</p>
        <p className='crops'>{farm.especie ?
            farm.especie.map((e) => e[lng[0]] || e[lng[1]]).join("-") + (farm.crops ? `-${capitalize(farm.crops)}` : "")
            :
            farm.crops ? capitalize(farm.crops) : "-"}
        </p>
    </div>
));

const FnWrapper = (props) => props && props.fn();
FnWrapper.displayName = "FnWrapper";

const Menubar = ({ historyRef, innerRef, mapRef, userInfo, dataSource, setDataSource, fields, setFields, setMenuIsOpen }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const params = getParams(location);
    const valueSelected = useRef(null);
    const [farmData] = useContext(FarmContext);

    useEffect(() => {
        async function getDatos() {
            try {
                setDataSource({ loading: true, data: null });
                const datos = await RestAPI.get("apiAdminDropControl", "/accounts", {});
                const newData = await transformData(datos);
                newData.sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                setDataSource({ loading: false, data: newData });
            } catch (e) {
                setDataSource({ loading: false, data: null });
                throw (e);
            }
        }
        getDatos();
    }, [userInfo.sub]);

    const mediaQuery = "(min-width: 600px)";

    useImperativeHandle(innerRef, () => ({
        updateFarmInfo: (newData) => updateFarmData(newData, dataSource.data, setDataSource, valueSelected)
    }), [dataSource.data]);

    useEffect(() => {
        const selectedId = valueSelected.current && valueSelected.current.id;
        const selectedFarmId = selectedId && selectedId.split("-")[1];
        const stringFarmId = params.farm ? ("" + params.farm) : null;

        if (stringFarmId && dataSource.data && selectedFarmId !== stringFarmId) {
            dataSource.data.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            let id;
            for (const dato of dataSource.data) {
                for (const d of dato.subChild) {
                    id = d.id.split("-")[1];
                    if (id === stringFarmId) {
                        valueSelected.current = d;
                        setDataSource((dataSource) => ({ ...dataSource })); // force re-render
                        return;
                    }
                }
            }
        }
    }, [params.farm, dataSource.data]);

    const [show, setShowInternal] = useState(true);
    const documentProp = useRef(null);
    const containerTree = useRef(null);
    const collapseListRef = useRef(null);

    const setShow = useMemo(() => {
        const setHeight = () => {
            if (documentProp.current) {
                setTimeout(() => containerTree.current &&
          (containerTree.current.style.maxHeight = `calc(100vh - (55px + ${documentProp.current.clientHeight}px + 40px)`, 1)
                );
            }
            if (collapseListRef.current) {
                collapseListRef.current.removeEventListener("webkitAnimationEnd", setHeight);
                collapseListRef.current.removeEventListener("animationend", setHeight);
                collapseListRef.current.removeEventListener("oanimationend", setHeight);
            }
        };

        const addEvents = (scroll) => {
            if (collapseListRef.current) {
                if (scroll) {
                    setTimeout(() => collapseListRef.current.scroll({ top: 0, behavior: "smooth" }), 0);
                }
                collapseListRef.current.addEventListener("webkitAnimationEnd", setHeight);
                collapseListRef.current.addEventListener("animationend", setHeight);
                collapseListRef.current.addEventListener("oanimationend", setHeight);
            }
        };

        return (newShow, scroll) => setShowInternal((oldShow) => {
            if (oldShow !== newShow) addEvents(scroll);
            return newShow;
        });
    }, [documentProp, containerTree, collapseListRef]);

    const langs = useMemo(() => getBaseLangs(i18n), [i18n.language]);

    const listaCampos = () => {
        return (
            <>
                <div className='campos-title'>
                    {fields &&
            <span className="titulo-lista">
                <span className='icono-campos-oscuro' />
                <span className='titulo-texto'>{t("menu.farms")} [ {fields.length} ]</span>
                {fields.length > 1}
                <i className={show?"pi pi-plus":"pi pi-minus"} style={{marginLeft:"8px"}}onClick={() => {
                    if (!(fields.length > 1)) return;
                    setShow(!show, !show);
                }}
                />
            </span>}
                </div>

                <div id="collapseList" ref={collapseListRef} className={show && fields && (fields.length > 1) ? "lista-campo-contenedor cerrada" : "lista-campo-contenedor"}>
                    {valueSelected.current &&
            <FarmElement key={valueSelected.current.id} setShow={setShow} history={historyRef.current} navigate={navigate} farm={valueSelected.current}
                t={t} lng={langs} isSelected
            />}
                    {fields && fields.map((f) => {
                        // the selected farm is shown first
                        if (valueSelected.current && (valueSelected.current.name === f.name)) return null;
                        return <FarmElement key={f.id} setShow={setShow} history={historyRef.current} navigate={navigate} farm={f}
                            t={t} lng={langs} isSelected={false}
                        />;
                    })}
                </div>
            </>
        );
    };

    const { onMouseEnterItem, onMouseLeaveItem } = useMemo(() => {
        const currentHover = {};

        const toggleHover = (target, isHovered) => {
            const updateFunction = mapRef.current && mapRef.current.setSelected;

            let elType, currentNode = target;
            while (currentNode) {
                elType = currentNode.getAttribute("data-pc-section");

                if (elType === "menuitem") {
                    break;
                } else if (elType === "root") {
                    currentNode = null;
                } else {
                    currentNode = currentNode.parentNode;
                }
            }

            if (!currentNode) return;

            const [__ignored, uid_option1, uid_option2] = (
                currentNode.getAttribute("id")
            )?.split("_") ?? [];

            let uid = uid_option1;
            if (!uid || !(uid.startsWith("nodo-") || uid.startsWith("sector-"))) {
                uid = uid_option2;
                if (!uid || !(uid.startsWith("nodo-") || uid.startsWith("sector-"))) return;
            }

            if (isHovered) {
                // do nothing if this is already saved
                if (currentHover.uid === uid) return;
                currentHover.uid = uid;
            } else {
                // do nothing if this is not saved
                if (currentHover.uid !== uid) return;
                delete currentHover.uid;
            }

            if (currentHover.debouncer) {
                clearTimeout(currentHover.debouncer);
            }
            currentHover.debouncer = setTimeout(() => {
                if (currentHover.debounced === currentHover.uid) return;
                currentHover.debounced = currentHover.uid;

                updateFunction && updateFunction(currentHover.debounced);
            }, 40);
        };

        return {
            onMouseEnterItem: (e) => toggleHover(e.currentTarget, true),
            onMouseLeaveItem: (e) => toggleHover(e.currentTarget, false)
        };
    }, [mapRef]);

    const isMobile = !useMatchMedia(mediaQuery);
    useEffect(() => {
        setMenuIsOpen(!isMobile);
    }, [isMobile]);

    return (
        <>
            {dataSource.loading === true && <div className='row-container'><LoadingDialog /></div>}
            <div id="sidebar-menu" className="sidebar-content">
                <div className="main-menu" >
                    <div id="documentProp" className="cuentas-campos-container" ref={documentProp}>
                        <ErrorBoundary name="Select">
                            <Selector history={historyRef.current} navigate={navigate} dataSource={dataSource.data} fields={fields}
                                setFields={setFields} redirectFarm={redirectFarm}
                                routeAccount={params.account} farmAccount={valueSelected.current && valueSelected.current.idRoot}
                                farmId={params.farm}
                            />
                            <FnWrapper fn={listaCampos} />
                        </ErrorBoundary>
                    </div>

                    {fields && <div className='linea' />}

                    {
                        farmData?.metadata?.need_approval?
                            <div className="node-approval"
                                onClick={(e) => {
                                    navigate({ pathname: "/farms/billing/nodeapproval", search: `?farm=${farmData?.dataFarm?.campo_id}` }, { state: { expanded: [], updateTree: true } });
                                }}
                            >
                                <i className="pi pi-cloud"  />
                                <p style={{ flexGrow: 1, margin:"0 0 0 8px", textTransform:"uppercase" }}>{t("billing.nodes_approval_header")}</p>
                                <i className="pi pi-info-circle" style={{ color: "#B85A08" }} />
                            </div>
                            :null
                    }
                    <div id="containerTree" className="treeview-container"
                        onClick={() => setShow(true, true)}
                    >
                        <ErrorBoundary name="Tree">
                            <Suspense>
                                <FarmTreeView history={historyRef.current} onMouseEnterItem={onMouseEnterItem} onMouseLeaveItem={onMouseLeaveItem} />
                            </Suspense>
                        </ErrorBoundary>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Menubar;
