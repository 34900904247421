import withName from "../../hoc/withName";
import { Button } from "primereact/button";
import { faCheckCircle, faTimesCircle, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ButtonPrime = ({t, template=null,onClick,raised=true,children=null,form,small,link, type,className, ...btnProps}) => {

    if(template==="formSave"){
        return(
            <Button className="faBtn" type={form || type==="submit" ? "submit" : "button"} onClick={onClick} {...btnProps}>
                <FontAwesomeIcon
                    size="lg" style={{ color: "green" }} icon={faCheckCircle}
                    type={form ? "submit" : "button"} form={form}
                >
                    {t("forms.save")}
                </FontAwesomeIcon>
            </Button>
        );
    }
    if(template==="formCancel"){
        return(
            <Button className="faBtn" type={form ? "submit" : "button"} onClick={onClick} {...btnProps}>
                <FontAwesomeIcon
                    size="lg" style={{ color: "tomato", marginRight: "10px", marginLeft: "4px" }} icon={faTimesCircle}
                >
                    {t("forms.save")}
                </FontAwesomeIcon>
            </Button>
        );
    }
    if(template==="formPlus"){
        return(
            <Button className="faBtn" type="button" onClick={onClick} {...btnProps}>
                <FontAwesomeIcon
                    size="lg" style={{ color: "darkcyan"}} icon={faPlusCircle}
                >
                    {t("forms.save")}
                </FontAwesomeIcon>
            </Button>
        );
    }
    if(template==="formRemove"){
        return(
            <Button className="faBtn" type="button" onClick={onClick} {...btnProps}>
                <FontAwesomeIcon
                    size="lg" style={{ color: "tomato"}} icon={faTrashAlt}
                >
                    {t("graficador.remove")}
                </FontAwesomeIcon>
            </Button>
        );
    }
    return (
        <Button type={type ? type : "button"} className={`primeBtn ${link?"p-button-link":""} ${small?"small":""} ${className?className:""}`} onClick={onClick} raised={raised} {...btnProps}>
            {children}
        </Button>

    );
};

export default withName(ButtonPrime, "ButtonPrime");
