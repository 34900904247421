import { useContext, useEffect, useMemo, useRef, useState, Suspense, createContext } from "react";
import { matchPath, useLocation, useNavigate, useNavigationType, useMatches } from "react-router";
import { useTranslation } from "react-i18next";

import { PanelMenu } from "primereact/panelmenu";
import { faFileAlt, faPoll, faStore, faTruck, faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FarmContext } from "../../contexts/FarmContext";
import switchTipoNodo from "../../utils/switchTipoNodo";
import getBaseLangs from "../../utils/language";

import { icon } from "@fortawesome/fontawesome-svg-core";
import { faCrosshairs, faCubes, faSlash, faTimesCircle, faDownload, faTint, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { putLog } from "../../utils/logcache";
import Loading from "../../img/loading-borde-suave.gif";
import ButtonPrime from "../PrimeComponents/ButtonPrime";

const [noDataWhiteBlue] = icon(faTimesCircle, { classes: ["fa-stack-1x"] }).html;
const [noLocationWhiteBlue] = icon(faCrosshairs, { classes: ["fa-stack-1x"] }).html;
const [iconBanWhiteBlue] = icon(faSlash, { classes: ["fa-stack-1x"], }).html;

const claseTipoNodo = (tipo, esGw) => {
    if (tipo === 1 || tipo === 2) {
        if (esGw) {
            return "gw-nodo-x1";
        } else {
            return "nodo-x1";
        }
    }

    if (tipo === 7 || tipo === 8) {
        if (esGw) {
            return "gw-nodo-c1";
        } else {
            return  "nodo-c1";
        }
    }

    if (tipo === 9) {
        return  "nodo-m1";
    }

    if (tipo === 10) {
        return "nodo-m1-3g";
    }

    if (tipo === 16) {
        if (esGw) {
            return "gw-nodo-v1";
        } else {
            return  "nodo-v1";
        }
    }
};

const translateName = (t, dato, objeto) => {
    if (dato.etiqueta === "titulo") {
        const transName = t(`farms.etiqueta.${objeto}`);
        if (dato.nivel === 1) {
            return `${transName} ${dato.cantidad}`;
        }
        return transName;
    } else if (dato.etiqueta === "hijo") {
        if (objeto === "sector") {
            const tipos =
        Array.isArray(dato.tipo) &&
        dato.tipo.map((tipo) => tipo && t(`tipo.sector.${tipo}Cap`));
            const resultado = (Array.isArray(tipos) && tipos.join(" - ")) || "";
            return resultado;
        }
    }
};

const switchTipoHidraulica = (t, param, es_monitoreo, tiene_controlador) => {
    switch (param) {
        case 1:
            //return 'PUMP';
            return t("farms.tipoHidraulica.pump");
        case 2:
            //return 'VALVE';
            return es_monitoreo ? t("farms.tipoHidraulica.monitoringValve") : t("farms.tipoHidraulica.valve");
        case 3:
            //return 'WATER SOURCE';
            return t("farms.tipoHidraulica.waterSource");
        case 4:
            //return 'TANK';
            return t("farms.tipoHidraulica.tank");
        case 5:
            //return 'PH INJECTOR';
            return t("farms.tipoHidraulica.phInjector");
        case 6:
            //return 'FERTILIZER INJECTOR';
            if(tiene_controlador){
                return t("farms.tipoHidraulica.fertiwise");
            }else {
                return t("farms.tipoHidraulica.fertilizerInjector");
            }
        case 9:
            //return 'WELL';
            return t("farms.tipoHidraulica.well");
        case 14:
            //return 'VFD';
            return t("farms.tipoHidraulica.vfd");
        case 16:
            //return 'BACKFLUSH STATION';
            return t("farms.tipoHidraulica.bfStation");
        default:
            return "";
    }
};

const nodeNav = (dato, options, treeRef, navigate, history, farmId) => {
    try {
        const nodo_id = dato && dato.id;

        /** @type {HTMLDivElement} */
        const tree = treeRef.current.getElement();

        const expanded = { state: { expanded: [] } };
        const panelId = options?.props?.panelId ?? "";
        const prefix = panelId + "_";
        let elType, elId, currentNode = tree.querySelector(`#${prefix}${nodo_id}`);

        while (currentNode) {
            elId = currentNode.getAttribute("id");
            if (elId) expanded.state.expanded.unshift(elId.replace(prefix, ""));

            currentNode = currentNode.parentNode;
            while (currentNode) {
                elType = currentNode.getAttribute("data-pc-section");

                if (elType === "menuitem") {
                    break;
                } else if (currentNode === tree) {
                    currentNode = null;
                } else {
                    currentNode = currentNode.parentNode;
                }
            }
        }

        // At this point we have all of the elements, except for the first one (because that one is not a menuitem, but a section)
        if (expanded.state.expanded[0] && Array.isArray(options?.props?.menuProps?.model)) {
            const model = options?.props?.menuProps?.model;
            const firstChildId = expanded.state.expanded[0];
            if (Array.isArray(model)) {
                const firstItem = model.find(i => {
                    return Array.isArray(i?.items) && i.items.some(c => c.id === firstChildId);
                });

                if (firstItem) expanded.state.expanded.unshift(firstItem.id);
            }

            // set base ids
            expanded.state.expanded = expanded.state.expanded.map(i => {
                const [objeto_id, parent] = i?.split("_") || [];
                return objeto_id;
            });
        }

        const [objeto_id, parent] = (nodo_id && nodo_id.split("_")) || [];
        const [objeto, id] = (objeto_id && objeto_id.split("-")) || [];

        const location = history.location;
        const prevSearch = location && location.search;
        const prevParams = new URLSearchParams(prevSearch);

        if (objeto === "actuador" && dato.etiqueta !== "titulo") {
            const newSearch = `?farm=${farmId}&actuator=${id}`;
            if (prevParams.get("actuator") !== id) {
                navigate({ pathname: "/farms/actuator", search: newSearch }, expanded);
            }
        } else if (objeto === "sensor" && dato.etiqueta !== "titulo") {
            const newSearch = `?farm=${farmId}&sensor=${id}`;
            if (prevParams.get("sensor") !== id) {
                navigate({ pathname: "/farms/sensor", search: newSearch }, expanded);
            }
        } else if (objeto === "hidraulica" && dato.etiqueta !== "titulo") {
            const newSearch = `?farm=${farmId}&hydraulic=${id}`;
            if (prevParams.get("hydraulic") !== id) {
                navigate({ pathname: "/farms/hydraulic", search: newSearch }, expanded);
            }
        } else if (objeto === "equipo" && dato.etiqueta !== "titulo") {
            const newSearch = `?farm=${farmId}&pumpsys=${id}`;
            if (prevParams.get("pumpsys") !== id) {
                navigate({ pathname: "/farms/pumpsys", search: newSearch }, expanded);
            }
        } else if (objeto === "sector" && dato.etiqueta !== "titulo") {
            const newSearch = `?farm=${farmId}&zone=${id}`;
            if (prevParams.get("zone") !== id) {
                navigate({ pathname: "/farms/zone", search: newSearch }, expanded);
            }
        } else if (objeto === "nodo") {
            const newSearch = `?farm=${farmId}&node=${id}`;
            if (prevParams.get("node") !== id) {
                navigate({ pathname: "/farms/node", search: newSearch }, expanded);
            }
        } else if (objeto === "thirdparties") {
            const newSearch = `?farm=${farmId}&thirdparties=${id}`;
            if (prevSearch !== newSearch) {
                navigate({ pathname: "/farms/thirdparties", search: newSearch }, expanded);
            }
        } else if (objeto === "permiso") {
            const newSearch = `?farm=${farmId}&user=${id}`;
            if (prevSearch !== newSearch) {
                navigate({ pathname: "/farms/user", search: newSearch }, expanded);
            }
        } else if (objeto === "usuarios") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/users", location.pathname)) {
                navigate({ pathname: "/farms/users", search: newSearch }, expanded);
            }
        } /*else if (objeto === "sass") {
      const newSearch = `?farm=${farmId}`;
      if (!matchPath("/farms/sass", location.pathname)) {
        navigate({ pathname: "/farms/sass", search: newSearch }, expanded);
      }
    }*/else if (objeto === "rawdata") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/rawdata", location.pathname)) {
                navigate({ pathname: "/farms/rawdata", search: newSearch }, expanded);
            }
        }else if (objeto === "pozo") {
            const newSearch = `?farm=${farmId}&well=${id}`;
            if (prevSearch !== newSearch) {
                navigate({ pathname: "/farms/well", search: newSearch }, expanded);
            }
        } else if (objeto === "graficador") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/customchart", location.pathname)) {
                navigate({ pathname: "/farms/customchart", search: newSearch }, expanded);
            }
        }else if (objeto === "integradores") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/integrators", location.pathname)) {
                navigate({ pathname: "/farms/integrators", search: newSearch }, expanded);
            }
        }else if (objeto === "external") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/external", location.pathname)) {
                navigate({ pathname: "/farms/external", search: newSearch }, expanded);
            }
        } else if (objeto === "systemEvents") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/systemEvents", location.pathname)) {
                navigate({ pathname: "/farms/systemEvents", search: newSearch }, expanded);
            }
        } else if (objeto === "reports") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/reports", location.pathname)) {
                navigate({ pathname: "/farms/reports", search: newSearch }, expanded);
            }
        } else if (objeto === "billingparams") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/billingparams", location.pathname)) {
                navigate({ pathname: "/farms/billingparams", search: newSearch }, expanded);
            }
        } else if (objeto === "marketplace") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/marketplace", location.pathname)) {
                navigate({ pathname: "/farms/marketplace", search: newSearch }, expanded);
            }
        } else if (objeto === "customizations") {
            const newSearch = `?farm=${farmId}`;
            if (!matchPath("/farms/customizations", location.pathname)) {
                navigate({ pathname: "/farms/customizations", search: newSearch }, expanded);
            }
        } else {
            return false;
        }
        return true;
    } catch (error) {
        console.error(error);
    }
};

const SelectedItemContext = createContext(null);

const TranslateElementsPrime = ({ datos, options, treeRef, history, setSelectedItem, onMouseEnterItem, onMouseLeaveItem }) => {
    const [dataSource] = useContext(FarmContext);
    const { t, i18n } = useTranslation();
    const langs = getBaseLangs(i18n);
    const navigate = useNavigate();

    const selectedItem = useContext(SelectedItemContext);
    const [objeto_id] = datos?.id?.split("_") ?? [];

    if (datos === null || datos === undefined || datos.id === null) return null;
    const [objeto] = (datos.id && datos.id.split("-")) || [];
    let content = [];
    switch (objeto) {
        case "network":{
            content = <><span className='menu-icons-prime red-oscuro' /><p className='orden-solo uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "nodo":{
            content = <>
                <span className={`menu-icons-prime ${claseTipoNodo(datos.tipo, datos.is_gw)}`}> </span>
                <p className='orden'>{datos.nombre}</p>
                <div className='cantidad'>
                    {datos.noData && <span id="icon-white-blue" className="fa-stack statusIcon midIcon" title={t("status.noData")}>
                        <a dangerouslySetInnerHTML={{ __html: noDataWhiteBlue }} />
                    </span>}
                    {datos.noLocation && <span id="icon-white-blue" className="fa-stack statusIcon midIcon" title={t("maps.nodata")}>
                        <a dangerouslySetInnerHTML={{ __html: noLocationWhiteBlue }} />
                        <a dangerouslySetInnerHTML={{ __html: iconBanWhiteBlue }} />
                    </span>}
                    {datos.etiqueta === "GW" && datos.cantNodos > 0 && <span className="position-relative statusIcon">{datos.cantNodos}</span>}
                </div>
                <p className='tipo'>{switchTipoNodo(datos.tipo, 0)}{datos.v1_childs?.length > 0 ? ` [${switchTipoNodo(16, 0)}]` : ""}</p>
            </>;
            break;
        }
        case "actuadores":{
            content = <><span className='menu-icons-prime actuadores-oscuro' /><p className='orden-solo uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "actuador":{
            content = <><span className='menu-icons-prime actuador-oscuro' /><p className='orden-solo'>{datos.tipo_asociacion_ha && t(`tipo.asociacion_ha.${datos.tipo_asociacion_ha}`, "")} - {datos.hidraulica || datos.nombre} </p></>;
            break;
        }
        case "hidraulicas":{
            content = <>
                <span className='menu-icons-prime hidraulicas-oscuro' />
                <p className='orden uppercase'> {translateName(t,datos, objeto)} </p>
                {datos.etiqueta==="titulo" ?
                    <p className='tipo uppercase'>
                        {datos.tipoFuente > 0 || datos.tipoBomba > 0 || datos.tipoEstanque > 0 || datos.tipoInyectorF ||
              datos.tipoInyectorPH > 0 || datos.tipoRetrolavado > 0 || datos.tipoValvula > 0 || datos.tipoPozo > 0 || datos.tipoVFD > 0 ||
              datos.tipoValvulaMonitoreo > 0 ?
                            <>
                                {datos.tipoFuente > 0 ? `${t("farms.tipoHidraulica.waterSource")}(${datos.tipoFuente}) ` : ""}
                                {datos.tipoBomba > 0 ? `${t("farms.tipoHidraulica.pump")}(${datos.tipoBomba}) ` : ""}
                                {datos.tipoEstanque > 0 ? `${t("farms.tipoHidraulica.tank")}(${datos.tipoEstanque}) ` : ""}
                                {datos.tipoInyectorF > 0 ? `${t("farms.tipoHidraulica.fertilizerInjector")}(${datos.tipoInyectorF}) ` : ""}
                                {datos.tipoInyectorPH > 0 ? `${t("farms.tipoHidraulica.phInjector")}(${datos.tipoInyectorPH}) ` : ""}
                                {datos.tipoRetrolavado > 0 ? `${t("farms.tipoHidraulica.bfStation")}(${datos.tipoRetrolavado}) ` : ""}
                                {datos.tipoValvula > 0 ? `${t("farms.tipoHidraulica.valve")}(${datos.tipoValvula}) ` : ""}
                                {datos.tipoValvulaMonitoreo > 0 ? `${t("farms.tipoHidraulica.monitoringValve")}(${datos.tipoValvulaMonitoreo}) ` : ""}
                                {datos.tipoPozo > 0 ? `${t("farms.tipoHidraulica.well")}(${datos.tipoPozo}) ` : ""}
                                {datos.tipoVFD > 0 ? `${t("farms.tipoHidraulica.vfd")}(${datos.tipoVFD}) ` : ""}
                            </>
                            :
                            "-"}
                    </p>
                    : null}
            </>;
            break;
        }
        case "hidraulica":{
            content = <>
                <span className='menu-icons-prime hidraulicas-oscuro' />
                <p className='orden'> {datos.nombre} </p>
                {datos.etiqueta !== "titulo" ?
                    <p className='tipo uppercase'>{switchTipoHidraulica(t, datos.tipo, datos.es_monitoreo, datos.tiene_controlador)}</p>
                    : null}
            </>;
            break;
        }
        case "sectores":{
            content = <><span className='menu-icons-prime sectores-oscuro' /><p className='orden uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "sector":{
            content = <>
                <span className='menu-icons-prime sector-oscuro' />
                <p className='orden'> {datos.nombre}{datos.superficie ? ` (${t("general.number", { value: datos.superficie })} ${datos.kilo_unidad})` : ""} </p>
                <div className='cantidad'>
                    {datos.noLocation && <span id="icon-white-blue" className="fa-stack statusIcon" title={t("maps.nodata")}>
                        <a dangerouslySetInnerHTML={{ __html: noLocationWhiteBlue }} />
                        <a dangerouslySetInnerHTML={{ __html: iconBanWhiteBlue }} />
                    </span>}
                </div>
                {datos.etiqueta === "hijo" ?
                    <p className='tipo'>{translateName(t,datos, objeto)}</p>
                    : null}
            </>;
            break;
        }
        case "sensores":{
            content = <><span className='menu-icons-prime sensores-oscuro' /><p className='orden-solo uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "sensor":{
            content = <>
                <span className='menu-icons-prime sensor-oscuro' />
                <p className='orden'>{datos.nombre_mutate ? (datos.tipo && (datos.tipo?.[langs?.[0]] || datos.tipo?.[langs?.[1]]) || datos.nombre) : datos.nombre}{datos.profundidad ? ` (${t("general.number", { value: datos.profundidad })} ${datos.centi_unidad})` : ""} </p>
                <p className='tipo'>{datos.nombre_mutate ? "" : (datos.tipo?(datos.tipo?.[langs?.[0]] || datos.tipo?.[langs?.[1]]):`sensor.${datos.sensor_id}`)} </p>
            </>;
            break;
        }
        case "externos":{
            content = <><FontAwesomeIcon className="faIcon" icon={faCubes} size="lg" /><p className='orden-solo uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "thirdparties":{
            content = <><FontAwesomeIcon className="faIcon absPos" icon={faCubes} size="lg" /><p className='orden'> {datos.nombre} </p><p className='tipo'>{switchTipoNodo(datos.tipo)}</p></>;
            break;
        }
        case "rawdata":{
            content = <><FontAwesomeIcon className="faIcon" icon={faDownload} size="lg" /><p className='orden-solo uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "billingparams":{
            content = <><FontAwesomeIcon className="faIcon" icon={faSlidersH} size="lg" /><p className='orden-solo uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "pozosdga":{
            content = <><FontAwesomeIcon className="faIcon" icon={faTint} size="lg" /><p className='orden-solo uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "pozo":{
            content = <><span className='menu-icons-prime hidraulicas-oscuro' /><p className='orden-solo'> {datos.nombre} </p></>;
            break;
        }
        case "systemEvents":{
            content = <><FontAwesomeIcon className="faIcon" icon={faFileAlt} size="lg" /><p className='orden-solo uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "reports":{
            content = <><FontAwesomeIcon className="faIcon" icon={faPoll} size="lg" /><p className='orden-solo uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "equipos":{
            content = <><span className='menu-icons-prime equipos-oscuro' /><p className='orden uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "equipo":{
            content = <><span className='menu-icons-prime equipo-oscuro' /><p className='orden-solo'> {datos.nombre} </p></>;
            break;
        }
        case "usuarios":{
            content = <><span className='menu-icons-prime usuarios-oscuro' /><p className='orden uppercase'> {translateName(t,datos, objeto)} </p></>;
            break;
        }
        case "integradores":{
            content = <><FontAwesomeIcon className="faIcon" icon={faTruck} size="lg" /><p className='orden-solo uppercase'>{translateName(t,datos, objeto)}</p></>;
            break;
        }
        case "permiso":{
            content = <><span className='menu-icons-prime usuario-oscuro' /><p className='orden'>{datos.nombre} {datos.apellido_paterno} {datos.apellido_materno}</p><p className='tipo'>{datos.email}</p></>;
            break;
        }
        case "nodos":{
            content = <><span className='menu-icons-prime nodo-oscuro' /><p className='orden-solo uppercase'>{translateName(t,datos, objeto)}</p></>;
            break;
        }
        case "graficador":{
            content = <><span className='menu-icons-prime graficador-oscuro' /><p className='orden-solo uppercase'>{translateName(t,datos, objeto)}</p></>;
            break;
        }
        case "external":{
            content = <><span className='menu-icons-prime external-providers-oscuro' /><p className='orden-solo uppercase'>{translateName(t,datos, objeto)}</p></>;
            break;
        }
        case "marketplace":{
            content = <><FontAwesomeIcon className="faIcon" icon={faStore} size="lg" /><p className='orden-solo uppercase'>{translateName(t,datos, objeto)}</p></>;
            break;
        }
        case "customizations":{
            content = <><FontAwesomeIcon className="faIcon" icon={faWrench} size="lg" /><p className='orden-solo uppercase'>{translateName(t,datos, objeto)}</p></>;
            break;
        }
    }

    return(
        <div className="toogleIconwrapper" onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseLeaveItem} >
            {
                datos.id.includes("-")?
                    datos?.items?.length>0 ?
                        <div className="toogleIcon"><i style={{ fontSize: "1rem", paddingTop:"0.5rem" }} className={options.active?"pi pi-angle-down":"pi pi-angle-right"} onClick={options.onClick}  /></div>
                        : <div className='iconFiller' />
                    :null
            }
            <div
                onClick={(args) => {
                    if (datos?.items?.length>0 && !datos.id.includes("-") && objeto !== "usuarios") {
                        options.onClick(args);
                    } else {
                        const [objeto_id] = datos?.id?.split("_") ?? [];
                        const navigated = nodeNav(datos, options, treeRef, navigate, history, dataSource?.dataFarm?.campo_id);
                        if (navigated) setSelectedItem(objeto_id);
                    }
                    args.stopPropagation();
                }}
                className={`treeview-texts-prime
          ${datos.etiqueta === "titulo" && datos.nivel !== 1 ? "title" : ""}
          ${objeto === "nodo" ? "node" : ""}
          ${(objeto_id && selectedItem === objeto_id) ? "activeNode":""}`}
            >
                {content}
                {(datos.items && !datos.id.includes("-")) ? <i className={options.active ? "pi pi-minus" : "pi pi-plus"} onClick={(args) => {
                    options.onClick(args);
                    args.stopPropagation();
                }}
                /> : null}
                {(datos.etiqueta === "titulo" && datos.id.search("-") !== -1) ? <div className="cantidad"><span className="position-relative statusIcon">{datos.cantidad}</span></div> : null}
            </div>
        </div>
    );
};

export const setTreeState = (menuTreeNodes, setExpandedKeys, setSelectedItem, locState) => {
    if (!Array.isArray(locState)) return;

    let i, j;
    let currentNode = null;
    let nextNode = { items: menuTreeNodes };

    const l = locState.length;
    const extraKeys = {};
    let validItem = null;

    for (i = 0; i < l; i++) {
        if (!nextNode) return;
        currentNode = nextNode;
        nextNode = null;

        if (!Array.isArray(currentNode.items)) return;

        for (j = 0; j < currentNode.items.length; j++) {
            const [objeto_id] = currentNode.items[j]?.id?.split("_") ?? [];

            if (objeto_id && (objeto_id === locState[i])) {
                nextNode = currentNode.items[j];
                if (!nextNode) return;

                if (i === (l - 1)) {
                    validItem = locState[i];
                } else {
                    extraKeys[nextNode.id] = true;
                }
                break;
            }
        }
    }

    setSelectedItem(validItem);
    setExpandedKeys?.(c => ({ ...c, ...extraKeys }));
};

const StateReseter = ({ menuTreeNodes, setExpandedKeys, setSelectedItem }) => {
    const action = useNavigationType();
    const location = useLocation();

    useEffect(() => {
        const { pathname, state } = location || {};

        if (state && (action === "POP" || state.updateTree)) {
            setTreeState(menuTreeNodes, setExpandedKeys, setSelectedItem, state && state.expanded);
        } else if (action && !matchPath("/farms/*", pathname)) {
            // clean selected nodes if, for example, we are in "/personal" or "/account"
            setSelectedItem(null);
        }

        putLog("new loc:" + pathname + ", action: " + action, 2);
    }, [menuTreeNodes, location, action]);

    return null;
};

const FarmTreeView = ({ history, onMouseEnterItem, onMouseLeaveItem }) => {
    const [dataSource] = useContext(FarmContext);
    const [menuTreeNodes, setMenuTreeNodes] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const treeRef = useRef(null);

    const nodeTemplate = useMemo(() => {
        const FarmNode = (datos, options) => <TranslateElementsPrime datos={datos} options={options} history={history} treeRef={treeRef} setSelectedItem={setSelectedItem} onMouseEnterItem={onMouseEnterItem} onMouseLeaveItem={onMouseLeaveItem} />;
        return FarmNode;
    }, [onMouseEnterItem, onMouseLeaveItem]);

    const setTreeKeys = (treeNode, extraKey, level) => {
        const real_id = treeNode.original_id ?? treeNode.id;

        treeNode.original_id = real_id;
        treeNode.id = level>1 ? `${real_id}_${extraKey}` : real_id;
        treeNode.key = treeNode.id;
        treeNode.template = nodeTemplate;

        if (Array.isArray(treeNode.items)) {
            treeNode.items = treeNode.items.map((child, i) => {
                return setTreeKeys({ ...child }, `${extraKey}_${i}`, level + 1);
            });
        }
        return treeNode;
    };

    useEffect(() => {
        if (dataSource.data) {
            const newData = dataSource.data.map((treeNode) => {
                return setTreeKeys({ ...treeNode }, `${treeNode.id}`, 0);
            });
            setMenuTreeNodes(newData);
            dataSource.data = newData;
        }
    }, [dataSource]);

    return (
        <>
            <StateReseter menuTreeNodes={menuTreeNodes} setExpandedKeys={setExpandedKeys} setSelectedItem={setSelectedItem} />
            <Suspense fallback={<div className='row-container'><img className='img-loading' src={Loading} alt="Loading..." /></div>}>
                {
                    !dataSource?.metadata?.politicas_aceptadas ? null :
                        <SelectedItemContext.Provider value={selectedItem}>
                            <PanelMenu
                                ref={treeRef}
                                model={menuTreeNodes}
                                id="farmPM"
                                expandedKeys={expandedKeys}
                                onExpandedKeysChange={setExpandedKeys}
                                multiple
                            />
                        </SelectedItemContext.Provider>
                }
            </Suspense>
        </>
    );
};

export { FarmTreeView };
